import clsx from 'clsx';
import * as React from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import Button from '@components/Button';
import Link from '@components/Link';
import Logo from '@components/Logo';
import useWindowSize from '@utils/hooks/useWindowSize';
import { addSearchParamsToHref } from '@utils/urls';
import { NavFragmentFragment } from '@typings/prismic-types';
import ArrowRightIcon from '@icons/arrow-right.svg';
import BetaIcon from '@icons/beta.svg';
import ChevronIcon from '@icons/chevron-down.svg';
import CloseIcon from '@icons/close.svg';
import MenuIcon from '@icons/hamburger-menu.svg';
import BranchIcon from '@icons/subnav/branch.svg';
import EditIcon from '@icons/subnav/edit.svg';
import GraphIcon from '@icons/subnav/graph.svg';
import SlidersIcon from '@icons/subnav/sliders.svg';
import TeamIcon from '@icons/subnav/team.svg';
import * as styles from './styles.module.scss';

interface Props {
  nav: NavFragmentFragment;
  sticky?: boolean;
}

const Nav = ({ nav, sticky = true }: Props) => {
  const navData = nav.data;
  const [openIndex, setOpenIndex] = React.useState(-1);
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
  const windowSize = useWindowSize();
  const headerRef = React.useRef(null);

  React.useEffect(() => {
    if (windowSize.isMobile) {
      return null;
    }

    function closeDropdown(e: Event) {
      if (openIndex > -1 && !headerRef.current.contains(e.target)) {
        setOpenIndex(-1);
      }
    }

    function escapeDropdown(e: KeyboardEvent) {
      if (e.key === 'Escape' || e.keyCode === 27) {
        setOpenIndex(-1);
      }
    }

    window.addEventListener('click', closeDropdown);
    window.addEventListener('keydown', escapeDropdown);

    return () => {
      window.removeEventListener('click', closeDropdown);
      window.removeEventListener('keydown', escapeDropdown);
    };
  }, [openIndex, windowSize.isMobile]);

  return (
    <header
      className={`${styles.header} ${isMobileNavOpen ? styles.headerOpen : ``}`}
      style={{ position: sticky ? 'sticky' : 'relative' }}
    >
      <div className={`${styles.navWrapper}`}>
        <nav className={styles.nav}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo}>
              <GatsbyLink aria-label={`Homepage`} to="/">
                <Logo />
              </GatsbyLink>
              {navData.is_in_beta && (
                <span className={styles.beta}>
                  <BetaIcon />
                </span>
              )}
            </div>
            {windowSize.isMobile && (
              <Button
                onClick={() => setMobileNavOpen(!isMobileNavOpen)}
                type={'Icon'}
                icon={isMobileNavOpen ? <CloseIcon /> : <MenuIcon />}
                className={styles.menuIcon}
              />
            )}
          </div>
          <ul className={`${styles.navList}`} ref={headerRef}>
            {navData.links.map((navLink, index) => {
              const hasDropdown = navLink.items && navLink.items.length > 1;
              return (
                <PrimaryLink
                  key={navLink.id}
                  index={index}
                  navLink={navLink}
                  hasDropdown={hasDropdown}
                  onClick={() => (hasDropdown ? setOpenIndex(index) : null)}
                  isOpen={openIndex === index}
                  navLinksLength={navLink.items.length}
                />
              );
            })}
            {windowSize.isLessThanTablet && (
              <li>
                <Button
                  type={'Minimal'}
                  size={'Small'}
                  href={navData.secondary_cta_link.url}
                >
                  {navData.secondary_cta_label}
                </Button>
              </li>
            )}
          </ul>
        </nav>
        <div className={styles.ctaButtons}>
          {!windowSize.isLessThanTablet && (
            <Button
              type={'Minimal'}
              size={'Small'}
              href={navData.secondary_cta_link.url}
              useSearchParams
              hasMarginRight={true}
              className={styles.secondaryCta}
            >
              {navData.secondary_cta_label}
            </Button>
          )}
          <Button
            type={'Primary'}
            size={'Small'}
            href={navData.primary_cta_link.url}
            useSearchParams
          >
            {navData.primary_cta_label}
          </Button>
        </div>
      </div>
    </header>
  );
};

export const query = graphql`
  fragment NavFragment on PrismicNav {
    _previewable
    type
    data {
      is_in_beta
      primary_cta_label
      primary_cta_link {
        id
        url
      }
      secondary_cta_label
      secondary_cta_link {
        id
        url
      }
      links {
        ... on PrismicNavDataLinksNavItem {
          id
          slice_label
          slice_type
          primary {
            label {
              text
            }
            link {
              id
              url
            }
            sub_nav_cta_link_label {
              text
            }
            sub_nav_cta_link {
              id
              url
            }
          }
          items {
            sub_nav_link_label {
              text
            }
            sub_nav_link {
              id
              url
            }
            sub_nav_description
            sub_nav_link_icon
          }
        }
      }
    }
  }
`;

// Icon next to each link in subnav
const SubnavIcon = ({ icon }: { icon?: string }) => {
  switch (icon) {
    case 'Branch':
      return <BranchIcon />;
    case 'Edit':
      return <EditIcon />;
    case 'Graph':
      return <GraphIcon />;
    case 'Team':
      return <TeamIcon />;
    case 'Sliders':
      return <SlidersIcon />;
    default:
      return <div className="h-4 w-6" />;
  }
};

const PrimaryLink = ({
  hasDropdown,
  index,
  isOpen,
  onClick,
  navLink,
  navLinksLength,
}: {
  hasDropdown: boolean;
  index: number;
  isOpen: boolean;
  onClick: () => void;
  navLink: NavFragmentFragment['data']['links'][number];
  navLinksLength: number;
}) => {
  return (
    <li
      className={clsx(
        styles.primaryLink,
        hasDropdown && styles.dropdownParent,
        isOpen && hasDropdown && styles.dropdownParentHighlighted,
      )}
    >
      <Button
        type={'Minimal'}
        size={'Small'}
        href={navLink.primary.link.url || ''}
        onClick={onClick}
        hasMarginRight={index !== navLinksLength - 1}
        icon={hasDropdown ? <ChevronIcon /> : null}
      >
        {navLink.primary.label.text}
      </Button>
      {hasDropdown && <Dropdown isOpen={isOpen} navLink={navLink} />}
    </li>
  );
};

// Subnav dropdown
const Dropdown = ({
  isOpen,
  navLink,
}: {
  isOpen: boolean;
  navLink: NavFragmentFragment['data']['links'][number];
}) => {
  const windowSize = useWindowSize();
  return (
    <div className={clsx(styles.dropdown, isOpen && styles.dropdownOpen)}>
      <ul className={styles.dropdownList}>
        {navLink.items.map((item, i) => {
          return (
            <li className={styles.dropdownListItem} key={`subnav-link-${i}`}>
              <Button
                type={'Minimal'}
                href={item.sub_nav_link.url}
                iconPosition={'Left'}
              >
                {item.sub_nav_link_icon && (
                  <SubnavIcon icon={item.sub_nav_link_icon} />
                )}
                <div>
                  <span className={styles.subnavLabel}>
                    {item.sub_nav_link_label.text}
                  </span>
                  <span
                    className={clsx(
                      styles.subnavDescription,
                      'whitespace-normal',
                    )}
                  >
                    {item.sub_nav_description}
                  </span>
                </div>
              </Button>
            </li>
          );
        })}

        {!windowSize.isMobile &&
          navLink.primary.sub_nav_cta_link &&
          navLink.primary.sub_nav_cta_link.url && (
            <li className={`${styles.dropdownListItem} ${styles.subnavCta}`}>
              <Link
                href={addSearchParamsToHref(
                  navLink.primary.sub_nav_cta_link.url,
                )}
                icon={<ArrowRightIcon />}
              >
                {navLink.primary.sub_nav_cta_link_label.text}
              </Link>
            </li>
          )}
      </ul>
    </div>
  );
};

export default Nav;
